<template>
  <el-dialog :title=" '添加字段'" :close-on-click-modal="false" :visible.sync="visible" append-to-body @close="getdata">

    <div class="">
        <el-form ref="df">
            <el-form-item>
                <el-input v-model="manualEntryFieldName" placeholder="请输入字段名" class="inputClass"></el-input>
                <el-select v-model="manualEntryFieldType" placeholder="请选择字段种类">
                    <el-option
                        v-for="item in fieldTypes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">

                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="addManualEntryField()">创建</el-button>

            </el-form-item>
        </el-form>

  </div>
  </el-dialog>
</template>

<script>


export default {
  props:{
      addManualEntryId:{
          type:Number,
          required:true
      }
  },
  name:'Simple',
  components:{

  },
  data() {
    return {
      visible: false,
      code:'',
      fieldTypes:[
          {value:'1',label:'文本'},
      ],
      newAddManualEntryId:0,
      manualEntryFieldName:'',
      manualEntryFieldType:null
    };
  },
  watch:{
      addManualEntryId(newValue,oldValue){
        this.newAddManualEntryId=newValue
      }
  },
  created(){
    this.newAddManualEntryId=this.addManualEntryId;
    this.init();
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      this.visible=true;
      this.manualEntryFieldName='';
      this.manualEntryFieldType=null;
    },
    //添加字段
    addManualEntryField(){
        //判断是信息是否完整
        var complete=true;
        if(this.manualEntryFieldName==''||this.manualEntryFieldType==null){
            complete=false;
        }
        if(!complete){
            //如果有未填的信息，弹框提示
            this.$message({
                message:'请补全信息',
                type:'error'
            });
        }else{
            let data={
                manualEntryId:this.newAddManualEntryId,
                manualEntryFieldName:this.manualEntryFieldName,
                manualEntryFieldType:this.manualEntryFieldType
            }
            this.postRequest("/manualEntry/addField",data).then(response=> {
              if (response) {
                this.visible=false;
              }
            }).then(error => {
            })
            location.reload();
        }


    },

  },
};
</script>
<style scoped>
.inputClass{
    width: 250px;
}
.el-input {
    margin-right: 10px;
}
.el-select {
    width: 250px;
    margin-right: 10px;
}
.warningButton{
  color: #F56C6C;
}
</style>
